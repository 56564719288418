import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Typography, Paper, CircularProgress, TextField, Button, MenuItem} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chart from 'react-apexcharts';
import {getInvoicesReportApi, getDueInvoiceReportApi} from '../services/authService';
import {connect} from 'react-redux';
import {useIsMount} from './useIsMount';
import {consoleToLog, isInvoiceAccountCountryIndia} from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import {trackGAEvent, AccessLevel} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {setHeightForComponent} from '../util/AppUtil';
import { formatAmount } from '../util/currencyUtil';
import { usePropsContext } from './context';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from '@material-ui/core/Select';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PostAddIcon from '@material-ui/icons/PostAdd';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px",
        marginBottom:'40px'
    },
    paperRoot: {
        flexGrow: 1,
        marginTop:'16px'
    },
    dashboardText: {
        fontSize:'18px',
        color:'#4a5568',
        fontWeight: 500
    },
    tabContainer: {
        padding:'12px 16px',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    MuiTabsroot: {
        display:'flex',
        alignItems:'center',
        background:'#f0f3f7',
        borderRadius:'3px',
        width:"550px",
        minHeight:"36px !important"
    },
    MuiTabroot: {
        fontSize:'13.5px',
        fontWeight:'500',
        color: '#3c51be',
        minHeight:'0px !important',
        padding:'2px 0px !important'
    },
    Muiselected: {
        background:'#fff',
        borderRadius:'3px',
        color: '#3c51be !important',
        boxShadow: '0 1px 1px rgb(74 85 104 / 20%)'
    },
    graphContainer: {
        padding:'12px 0',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    valuesContainer: {
        padding:'18px 0',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    MuiTabsindicator: {
        backgroundColor: 'transparent !important',
        '& .PrivateTabIndicator-root-29' : {
            backgroundColor: '#fff !important'
        }
    },
    dueInvoicesHover: {
        '&:hover' : {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    customMarginTop: {
        marginTop:'16px'
    },
    selectIcon: {
        position: 'relative',
        left: '-21px'
    },
    btnContainer: {
        position:'absolute', 
        left:16, 
        display:'flex', 
        alignItems:'center'
    },
    labelText: {
        fontSize:'16px',
        fontWeight: 600,
        height: '70px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        '& .addIcon': {
            fontSize:'25px',
            visibility: 'hidden',
            position:'absolute',
            top:8,
            right: 8
        },
        '&:hover': {
            color:'#fff',
            '& .addIcon': {
                visibility: 'visible',
            },
        }
    },
    invoiceBtn: {
        background: '#E8F9FF',
        '&:hover': {
            background: '#1D67CD',
        }
    },
    estimateBtn: {
        background: '#EDECFE',
        '&:hover': {
            background: '#5521B4',
        }
    },
    documentBtn: {
        background: '#FFF2EB',
        '&:hover': {
            background: '#FB7730',
        }
    },
    proposalBtn: {
        background: '#E8F7F2',
        '& .aiLabelStyle' : {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize:'13px',
            fontWeight:600,
            width:'28px',
            height: '21px',
            background: '#9EF7DA',
            marginLeft:'8px',
            borderRadius:'4px'
        },
        '&:hover': {
            background: '#19AC76',
            '& .aiLabelStyle' : {
                color:"#212121"
        },
        }
    },
    btnIcon: {
        fontSize:'21px',
        marginRight:'6px'
    }
    
    }
));    

const DashboardComponent = (props) => {
    const isMount = useIsMount();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();


    const [value, setValue] = useState(0);
    const [invoiceReportData, setInvoiceReportData] = useState([]);
    const [invoiceSummaryReport, setInvoiceSummaryReport] = useState([]);
    const [dueInvoiceReportData, setDueInvoiceReportData] = useState([]);
    const [totalOutstandingAmount, setTotalOutStandingAmount] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tdsReportList, setTDSReportList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState('all');

    const { fullName, handleInvoicesDrawerOpen, onNewInvoiceClick, onNewEstimateClick, onNewProposalClick, onNewDcoumentClick } = usePropsContext();
    

    const is_email_verified = props.selectedAccount?.is_email_verified;

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const entityList = props.selectedAccount?.entities;
    const currenciesObj = props.selectedAccount?.currencies;

    useEffect(() => {
        document.title = `Dashboard - ${fullName}`;
    }, []);

    useEffect(() => {
            getInvoicesReport(value);
    }, []);

    useEffect(() => {
        if(!isMount) {
            setValue(0);
            getInvoicesReport(0);
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(value === 0) {
                //console.log('Current FY filter clicked');
                trackGAEvent(props.selectedAccount.name, 'Dashboard - Current FY filter clicked', `${props.user.firstname} ${props.user.lastname}`);
            } else if(value === 1) {
                //console.log('Previous FY filter clicked');
                trackGAEvent(props.selectedAccount.name, 'Dashboard - Previous FY filter clicked', `${props.user.firstname} ${props.user.lastname}`);
            } else {
                //console.log('Last 12 month filter clicked');
                trackGAEvent(props.selectedAccount.name, 'Dashboard - Last 12 month filter clicked', `${props.user.firstname} ${props.user.lastname}`);
            }
        }
    }, [value]);

    useEffect(() => {
        if(!isMount) {
            getInvoicesReport(value);
        }
    }, [selectedEntity])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        getInvoicesReport(newValue);
    };

    const getInvoicesReport = (tabValue) => {
        const invoice_account_id = props.selectedAccount?.id;
        const current_financial_year = tabValue === 0 ? true : false;
        const previous_financial_year = tabValue === 1 ? true : false;
        const last_12_months = tabValue === 2 ? true : false;
        const entity_id = selectedEntity === 'all' ? undefined : selectedEntity;

        getInvoicesReportApi(invoice_account_id, current_financial_year, previous_financial_year, last_12_months, entity_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getInvoicesReportApi: ", res);
                

                setInvoiceReportData(res?.invoice_report.slice(0, res?.invoice_report.length - 1).reverse());
                setInvoiceSummaryReport(res?.invoice_report[res?.invoice_report.length - 1]);
                setTDSReportList(res.tds_report);
                getDueInvoicesReport();
                
            })
            .catch((e) => {
                consoleToLog("Error getInvoicesReportApi: ", e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const getDueInvoicesReport = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = selectedEntity === 'all' ? undefined : selectedEntity;

        getDueInvoiceReportApi(invoice_account_id, entity_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getDueInvoiceReportApi: ", res);
                setLoading(false);

                setDueInvoiceReportData(res.slice(0, res.length - 1));
                setTotalOutStandingAmount(res[res.length - 1]);
            })
            .catch((e) => {
                consoleToLog("Error getDueInvoiceReportApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }


    const options = {
        chart: {
            type: 'bar',
            height: 350,
                toolbar: {
                    show: false
                }
        },
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: 'rounded',
            },
        },
        colors:[
            "#3c51be",
            "#00a367"
        ],
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        fill: {
            opacity: 0.9
        },
        toolbar: false,
        tooltip: {
            y: {
            formatter: function (val) {
                return formatAmount(val, currenciesObj)
                }
            }
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar'], // Ensure categories are always defined
        },
    };

    const PieChartData = {
        series:[3, 5],
        options:{
        chart:{
            width:380,
            type: "pie"
        },
        colors:[
            "#00cf95", 
            "#0291ff",
            "#FF5733",
            "#1B4F72",
            "#DC7633",
            "#F7DC6F",
            "#52BE80",
            "#A93226",
            "#273746",
            "#B7950B"
        ],
        legend:{
            show: true,
            position:"right",
            
        },
        responsive:[
            {
            breakpoint:480,
            options:{
                chart:{
                    width:200
                },
                legend:{
                    position:"bottom"
                }
            }
            }
        ],
        tooltip: {
            enabled: true,
            y: {
                formatter: function(val) {
                return  formatAmount(val, currenciesObj);
                },
            }
            }
        }  
    }  

    const categories = invoiceReportData.map((data)=> data.month + ' ' + data.year).reverse();
    const xaxis = {categories: categories}
    const options2={...options, xaxis: xaxis} 

    const seriesDataArr = () => {
        if (!invoiceReportData || invoiceReportData.length === 0) {
            return [{
                name: 'Invoiced',
                data: []
            }, {
                name: 'Collected',
                data: []
            }];
        }
        let invoiced_sumObj = {name: 'Invoiced', data:[]};
        let collected_sumObj = {name:"Collected", data:[]};
        invoiceReportData.forEach(item => {
            invoiced_sumObj.data.push(item.invoiced_sum ? Number(item.invoiced_sum.toFixed(0)) : 0);
            collected_sumObj.data.push(item.collected_sum ? Number(item.collected_sum.toFixed(0)) : 0);
        });
        invoiced_sumObj.data.reverse();
        collected_sumObj.data.reverse();
        return [invoiced_sumObj, collected_sumObj];
    }

    const seriesDataArr1 = () => {
        let seriesArr = [];
        let optionsArr = [];

        if(tdsReportList && tdsReportList.length > 0) {
            for(let i = 0; i < tdsReportList.length; i++) {
                tdsReportList[i].tds_type && seriesArr.push(tdsReportList[i].tds_total);
                tdsReportList[i].tds_type && optionsArr.push(tdsReportList[i].tds_type)
            }
        }

        var optionObj = {...PieChartData.options, labels: optionsArr}

        return {seriesArr, optionObj}
    }

    const onEntityChange = (e) => {
        setSelectedEntity(e.target.value);
    }

    return (
        <div className={classes.mainContent}>
            {loading ?
            <CircularProgress size={30} className={classes.loading}/>
            :
            <Container className={classes.container} maxWidth="xl">
                <Grid item container justifyContent='flex-end' spacing={1} style={{marginTop:'16px'}}>
                    <Grid item md={3}>
                            <Button className={`${classes.invoiceBtn} ${classes.labelText}`} 
                                fullWidth
                                onClick={onNewInvoiceClick}>
                                <div className={classes.btnContainer}>
                                    <DescriptionOutlinedIcon className={classes.btnIcon} />
                                    Invoice
                                </div>
                                <AddIcon className='addIcon'/>
                            </Button>
                    </Grid>

                    <Grid item md={3}>
                        <Button className={`${classes.estimateBtn} ${classes.labelText}`}  
                            fullWidth
                            onClick={onNewEstimateClick}>
                            <div className={classes.btnContainer}>
                                <AddCircleOutlineIcon className={classes.btnIcon} />
                                Estimate
                            </div>
                            <AddIcon className='addIcon'/>
                        </Button>
                    </Grid>

                    <Grid item md={3}>
                        <Button className={`${classes.documentBtn} ${classes.labelText}`} 
                            fullWidth
                            onClick={onNewDcoumentClick}>
                            <div className={classes.btnContainer}>
                                <NoteAddOutlinedIcon className={classes.btnIcon} />
                                eSignature
                            </div>
                            <AddIcon className='addIcon'/>
                        </Button>
                    </Grid>

                    <Grid item md={3}>
                        <Button className={`${classes.proposalBtn} ${classes.labelText}`} 
                            fullWidth
                            onClick={onNewProposalClick}>
                            <div className={classes.btnContainer}>
                                <PostAddIcon className={classes.btnIcon} />
                                Proposal
                                <span className='aiLabelStyle'>AI</span>
                            </div>
                            <AddIcon className='addIcon'/>
                        </Button>
                    </Grid>
                </Grid>
                <Paper elevation={0} className={classes.paperRoot}>
                    <Grid item container
                        className={classes.tabContainer} 
                        justifyContent='space-between'
                        alignItems="center">
                        <Grid item>
                            {entityList && entityList.length > 1 &&
                            <Select autoWidth
                                variant='standard'
                                fullWidth
                                margin='dense'
                                value={selectedEntity}
                                onChange={onEntityChange}
                                disableUnderline
                                classes={{
                                    icon: classes.selectIcon,
                                }}
                                style={{background:'transparent'}}
                                >
                                <MenuItem value='all'>All</MenuItem>
                                {entityList && entityList.length > 0 &&
                                    entityList.map((entityItem) => (
                                        <MenuItem key={entityItem.id} value={entityItem.id}>
                                            {entityItem.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>}
                        </Grid>

                        <Grid item md={6} style={{flexBasis:'48%', maxWidth:'48%'}}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="fullWidth"
                                textColor="secondary"
                                aria-label="icon label tabs example"
                                className={classes.tabStyle}
                                classes={{
                                    root: classes.MuiTabsroot,
                                    indicator: classes.MuiTabsindicator
                                }}
                            >
                                <Tab
                                    style={{marginLeft:'5px'}} 
                                    classes={{
                                    root: classes.MuiTabroot,
                                    selected: classes.Muiselected
                                }} label="Current FY" />
                                <Tab classes={{
                                    root: classes.MuiTabroot,
                                    selected: classes.Muiselected
                                }} label="Previous FY" />
                                <Tab style={{marginRight:'5px'}} 
                                    classes={{
                                    root: classes.MuiTabroot,
                                    selected: classes.Muiselected
                                }} label="Last 12 months" />
                            </Tabs>
                        </Grid>
                    </Grid>
                    
                    <Grid item sm={12} className={classes.graphContainer}>
                        {invoiceReportData && invoiceReportData.length > 0 ? (
                            <Chart 
                                options={options2} 
                                series={seriesDataArr()} 
                                type="bar" 
                                height={350}
                            />
                        ) : (
                            <Typography style={{textAlign: 'center', padding: '20px'}}>
                                No data available
                            </Typography>
                        )}
                    </Grid>

                    <Grid item sm={12} className={classes.valuesContainer}>
                        <Grid item container justifyContent='center'>
                            <Grid item sm={3} className='borderRightStyle'>

                                <Grid item container direction='column'>
                                    <Grid item container alignItems="center">
                                        <div>
                                            <img src='/images/gray.svg'/>
                                        </div>

                                        <Typography className='texStyles' style={{marginLeft:'8px'}}>
                                            Invoiced
                                        </Typography>
                                    </Grid>

                                    <Typography className='valueTextStyles'>
                                        {formatAmount(invoiceSummaryReport[0]?.invoiced, currenciesObj)}
                                    </Typography>
                                </Grid>

                            </Grid>
                            
                            <Grid item sm={3} className='borderRightStyle'>
                                <Grid item container direction='column'>
                                    <Grid item container alignItems="center">
                                        <div>
                                            <img src='/images/blue.svg'/>
                                        </div>

                                        <Typography className='texStyles' style={{marginLeft:'8px'}}>
                                            Collected
                                        </Typography>
                                    </Grid>

                                    <Typography className='valueTextStyles'>
                                        {formatAmount(invoiceSummaryReport[0]?.collected, currenciesObj)}
                                    </Typography>
                                </Grid>

                            </Grid>

                            <Grid item sm={3} className='borderRightStyle'>
                                <Grid item container direction='column'>
                                    <Typography className='texStyles'>
                                        Due
                                    </Typography>

                                    <Typography className='valueTextStyles'>
                                        {formatAmount(invoiceSummaryReport[0]?.due, currenciesObj)} 
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item sm={3} className='borderRightStyle'>
                                <Grid item container direction='column'>
                                    <Typography className='pastDueTextStyle'>
                                        Past Due
                                    </Typography>

                                    <Typography className='pastDueTotalStyle'>
                                        {formatAmount(invoiceSummaryReport[0]?.past_due, currenciesObj)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>    

                    </Grid>

                    <Grid item sm={12} style={{padding:'16px 0px'}}>
                    {isInvoiceAccountCountryIndia(props.selectedAccount?.country) &&
                    <>
                        <Grid item>
                            <Typography className={classes.dashboardText} color="primary" style={{paddingLeft:'16px'}}>
                                TDS Payments
                            </Typography>
                        </Grid>

                        <div>
                            <Grid item container justifyContent='center' alignItem='center'>
                                {
                                    tdsReportList && tdsReportList.length > 0 ?
                                    <Grid item lg={6}>
                                        <Chart options={seriesDataArr1().optionObj} 
                                            series={seriesDataArr1().seriesArr} 
                                            type="pie" 
                                        /> 
                                    </Grid>
                                    :
                                    <Typography style={{fontSize:'16px', fontWeight:'600', padding:'32px 0px'}}>
                                        No TDS Payments Found
                                    </Typography>
                                }
                                
                            
                            </Grid>
                        </div>
                    </>}

                    </Grid>
                </Paper>

                <Paper elevation={0} className={classes.paperRoot}>
                    <Grid item className={classes.tabContainer}>
                        <Typography className={classes.dashboardText} color="primary">
                            Past Due Invoices
                        </Typography>
                    </Grid>

                    <Grid item container>
                        {dueInvoiceReportData && dueInvoiceReportData.length > 0 &&
                        dueInvoiceReportData.map((invoice) => {
                            return <Grid item sm={2} className='pastDueInvoiceStyle'>
                                        <Typography className='pastDueAmountStyle'>
                                            {formatAmount(invoice.due_invoice_sum ? invoice.due_invoice_sum  : 0, currenciesObj )}
                                        </Typography>
                                        <Typography className={`pastDueCountStyle ${classes.dueInvoicesHover}`}
                                            onClick={() => handleInvoicesDrawerOpen(invoice, selectedEntity)}>
                                            {invoice.due_invoice_count + ' Invoices'}
                                        </Typography>
                                        <Typography className='pastDueRangeStyle'>
                                            {invoice.data_for}
                                        </Typography>
                                    </Grid>
                        })
                        }
                        <Grid item sm={4} className='totalOutstandingContainerStyle'>
                            <div style={{paddingBottom:'32px', paddingRight:'24px'}}>
                                <Typography className='pastDueRangeStyle'>
                                    Total Outstanding
                                </Typography>
                                <Typography className='totalOutstandingAmountStyle'>
                                    {formatAmount(totalOutstandingAmount[0]?.total_outstanding, currenciesObj)}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);