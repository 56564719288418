import React, {useState, useEffect} from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import {Grid, Typography, Dialog, Avatar, Slide, TextField, InputAdornment, Tooltip, Divider, ClickAwayListener, Button} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import {getClientListApi} from '../../services/authService';
import {consoleToLog, boldString} from '../../util/AppUtil';
import {useIsMount} from '../useIsMount';
import {changeInvoiceCustomerApi} from '../../services/authService';
import {autoCompleteClientListApi, getClientDetailsObjApi} from '../../services/clientService';
import { useSnackbar } from 'notistack';
import { setSelectedDrawerState, SelectedDrawerState } from '../../actions/drawer';
import { history } from '../../routers/AppRouter';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    avatarStyle: {
        width:"28px",
        height:"28px",
        fontSize:'12px',
        fontWeight:'600',
        color:'#2B2B2B',
        marginRight:'-8px'
    },
    accountListContainer : {
        padding:'8px',
        borderRadius: '4px',
        cursor:'pointer',
        '&:hover' : {
            background: '#eef2f7'
        }
    },
    MuiTextFieldroot: {
        background:'#f0f3f7',
        border:'none'
    },
    list: {
        display: 'flex',
        cursor: 'pointer',
        alignItems:'center',
        padding:'0px 8px 0px 16px',
        '&:hover' : {
            background:'#F9F9F9'
        }
    },
    MuiFormControlroot: {
        '& .MuiOutlinedInput-input' :{
            paddingLeft: '16px',
        },   
    },
    listContainer : {
        width:'100% !important',
        '&:last-child .dropdownDivider' : {
            display: 'none'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    createItemButton: {
        marginTop:'8px',
        marginBottom:'8px',
        width:'96%', 
        color:'#1bac3b', 
        borderColor:'#d4d8de'
    },
    createItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    }
}))

const InvoiceModalComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const isMount = useIsMount();

    const [searchCustomer, setSearchCustomer] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(-1);

    const entityList = props.selectedAccount?.entities;

    useEffect(() => {
        if(entityList && entityList.length === 1 && !props.editInvoiceObj) {
            setSelectedEntity(entityList[0]);
        }

    }, [])

    const getClientsList = (text) => {
        const invoice_account_id = props.selectedAccount.id;

        if(!text) return;
        
        autoCompleteClientListApi(invoice_account_id, text)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response autoCompleteClientListApi: ', res);
            setCustomerList(res.companies);
        })
        .catch((e) => {
            consoleToLog('Response autoCompleteClientListApi ', e);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        })
    }  
    
    const getClientsDetailsObj = (client) => {
        const client_id = client.id;
        
        getClientDetailsObjApi(client_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getClientDetailsObjApi: ', res);

            if(props.openChangeCustomerModal) {
                const client_obj = res.client;
                
                let clientObj = {
                    id: client_obj.id,
                    name: client_obj.name,
                    address: client_obj.address,
                    country: client_obj.country,
                    state: client_obj.state,
                    tax_id: client_obj.tax_id,
                    email: client_obj.email,
                    invoice_account_id: client.invoice_account_id,
                    user_defined_field: client_obj.user_defined_field,
                    contacts: client_obj.contacts

                }

                history.push(`${window.location.pathname}`, {clientObj: clientObj});
                props.handleClickDialogClose();
            } else {
            
                history.push(props.showEstimateClicked ? '/estimate/create' : '/invoice/create', {clientObj: res.client, entityObj: selectedEntity});
                props.handleInvoiceDialogOpen();
                setSearchCustomer('');
                props.handleClickDialogClose();
            }
        })
        .catch((e) => {
            consoleToLog('Response getClientDetailsObjApi ', e);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        })
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    // const callChangeCustomerApi = (clientObj) => {
    //     const invoice_account_id = props.selectedAccount.id;
    //     const invoice_id = props.editInvoiceObj?.id;
    //     const client_id = clientObj?.id;

    //     // changeInvoiceCustomerApi(access_token, invoice_account_id, invoice_id, client_id)
    //     // .then((response) => {
    //     //     const res = response.data;
    //     //     consoleToLog('Response changeInvoiceCustomerApi: ', res);

    //     //     props.setEditInvoiceObj(res);
    //     // })
    //     // .catch((e) => {
    //     //     consoleToLog('Response changeInvoiceCustomerApi ', e);
    //     //     if (e.response.data && e.response.data.message) {
    //     //         return false;
    //     //     }
    //     // })

    // }

    const onCustomerItemClick = (clientObj) => {
            if(selectedEntity === -1) {
                enqueueSnackbar('Please select entity to create Invoice', {variant:'error'});
                return;
            } else {
                getClientsDetailsObj(clientObj);
            }
    }

    const onEntityClick = (e) => {
        const value = e.target.value;
        setSelectedEntity(value);
    }

    const onEntityClickFromEditInvoice = (e) => {
        const value = e.target.value;
        history.push(`${window.location.pathname}`, {entityObj: value});
        props.handleClickDialogClose();
    }

    const onCustomerClickFromEditInvoice = (clientObj) => {
        getClientsDetailsObj(clientObj);
    }


    const onClose = () => {
        props.handleClickDialogClose();
        selectedEntity !== -1 && setSelectedEntity(-1);
        
    }

    const showProperTitle = () => {
        if(!props?.editInvoiceObj) {
            return props.showEstimateClicked ? 'Create Estimate' : 'Create Invoice';
        } else {
            if(props.openChangeEntityModal) {
                return 'Change Entity';
            }
            if(props.openChangeCustomerModal) {
                return 'Change Client';
            }
        }
    }

    return (
            <div>
                <Dialog
                    open={props.openDialog}
                    //TransitionComponent={Transition}
                    classes={{
                        paper: classes.MuiDialogpaper}}
                    fullWidth
                    onClose={onClose}>
                    <Typography
                        className={classes.dialogTitle}>
                        <span>{showProperTitle()}</span>

                        <ClearIcon fontSize='xs' onClick={onClose}
                            style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                        </Typography>

                    {!props?.editInvoiceObj ?
                    <Grid item container direction="column" style={{padding:'8px 16px', position:'relative'}}> 

                        {entityList && entityList.length > 1 &&
                        <Grid item md={12}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Entity
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                classes={{
                                    root:classes.MuiTextFieldroot
                                }}
                                select
                                value={selectedEntity}
                                onChange={onEntityClick}
                            >
                                <MenuItem value={-1}>Select Entity</MenuItem>
                                {
                                    entityList.map((entityItem) => {
                                        return <MenuItem key={entityItem?.id} value={entityItem}>
                                                    {entityItem.name}
                                                </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>}
                            
                        
                        <Grid item md={12} style={{marginTop:entityList && entityList.length > 1 ? '8px' : '0px', position:'relative'}}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Client
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                classes={{
                                    root:classes.MuiTextFieldroot
                                }}
                                InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                                placeholder="Find Customer"
                                onChange={(e) => {
                                    setSearchCustomer(e.target.value);
                                    getClientsList(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        {/* <SearchIcon className={classes.searchicon}/> */}
                                        <i className="flaticon-search"></i>
                                    </InputAdornment>
                                    )
                                }}
                            />
                            {customerList && 
                            <ClickAwayListener onClickAway={() => {
                                setSearchCustomer('');
                                setCustomerList([]);
                            }}> 
                            <Grid item sm={12} style={{flexBasis:'94.8%', maxWidth:'94.8%'}}
                                className={`company__menu ${(searchCustomer !== '')  ? "active" : "inactive"}`}
                            >   
                                <Grid item container justifyContent='center'>
                                    <Button fullWidth
                                        className={classes.createItemButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            props.handleClientDrawerOpen();
                                            onClose();
                                        }}
                                    >
                                        <AddCircleOutlineIcon fontSize='small' className={classes.createItemIcon}/>  
                                        Add Client
                                    </Button>
                                </Grid>
                                <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
                                {customerList.length > 0 ? customerList.map((customer) => {
                                        const customer_name = customer.name;
                                        const compArr = customer.name.split(" ");
                                        let compStr1 = compArr[0];
                                        let compStr2 = compArr[1] ? compArr[1] : '';
                                        const finalStr = compStr1.charAt(0).toUpperCase() + compStr2.charAt(0).toUpperCase();

                                        return <ul key={customer.id} className={classes.listContainer}>
                                                        <li className={classes.list} 
                                                        onClick={() => onCustomerItemClick(customer)}
                                                        >
                                                        <Avatar
                                                            style={{background:'white', color:'#2B2B2B', border:'1px solid #2B2B2B'}}  
                                                            className={classes.avatarStyle}>
                                                            {finalStr}
                                                        </Avatar>
                                                        <span dangerouslySetInnerHTML={{__html: boldString(customer_name, searchCustomer)}}></span>
                                                    </li>
                                                    <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
                                                </ul>
                                    }) : <Grid item container justifyContent='center'>
                                            <li className={classes.list} style={{textAlign:'center'}}>
                                                <span style={{fontSize:'14px', color:'#555'}}>
                                                    No Clients Found
                                                </span>
                                            </li>
                                        </Grid>
                                }
                            </Grid>
                            </ClickAwayListener>}
                        </Grid>
                    </Grid> 
                    :
                    <Grid item container direction="column" style={{padding:'8px 16px', position:'relative'}}> 

                        {entityList && entityList.length > 1 && props.openChangeEntityModal && 
                        <Grid item md={12}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Entity
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                classes={{
                                    root:classes.MuiTextFieldroot
                                }}
                                select
                                value={selectedEntity}
                                onChange={onEntityClickFromEditInvoice}
                            >
                                <MenuItem value={-1}>Select Entity</MenuItem>
                                {
                                    entityList.map((entityItem) => {
                                        return <MenuItem key={entityItem?.id} value={entityItem}>
                                                    {entityItem.name}
                                                </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>}
                            
                        
                        {props.openChangeCustomerModal &&
                        <Grid item md={12} style={{marginTop:entityList && entityList.length > 1 ? '8px' : '0px'}}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Client
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                classes={{
                                    root:classes.MuiTextFieldroot
                                }}
                                InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                                placeholder="Find Customer"
                                onChange={(e) => {
                                    setSearchCustomer(e.target.value);
                                    getClientsList(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        {/* <SearchIcon className={classes.searchicon}/> */}
                                        <i className="flaticon-search"></i>
                                    </InputAdornment>
                                    )
                                }}
                            />
                            {customerList && 
                            <ClickAwayListener onClickAway={() => {
                                setSearchCustomer('');
                                setCustomerList([]);
                            }}> 
                            <Grid item sm={12} style={{flexBasis:'94.8%', maxWidth:'94.8%'}}
                                className={`company__menu ${(searchCustomer !== '')  ? "active" : "inactive"}`}
                            >   
                                
                                {customerList.length > 0 ? customerList.map((customer) => {
                                        const customer_name = customer.name;
                                        const compArr = customer.name.split(" ");
                                        let compStr1 = compArr[0];
                                        let compStr2 = compArr[1] ? compArr[1] : '';
                                        const finalStr = compStr1.charAt(0).toUpperCase() + compStr2.charAt(0).toUpperCase();

                                        return <ul key={customer.id} className={classes.listContainer}>
                                                        <li className={classes.list} 
                                                        onClick={() => onCustomerClickFromEditInvoice(customer)}
                                                        >
                                                        <Avatar
                                                            style={{background:'white', color:'#2B2B2B', border:'1px solid #2B2B2B'}}  
                                                            className={classes.avatarStyle}>
                                                            {finalStr}
                                                        </Avatar>
                                                        <span dangerouslySetInnerHTML={{__html: boldString(customer_name, searchCustomer)}}></span>
                                                    </li>
                                                    <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
                                                </ul>
                                    }) : <Typography style={{fontSize:'14px', color:'#555', display:'flex', alignItems:'center'}}>
                                            No Client Found
                                        </Typography>
                                }
                            </Grid>
                            </ClickAwayListener>}
                        </Grid>}
                    </Grid> 
                    }
                </Dialog>
            </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModalComponent);
