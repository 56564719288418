import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Typography,Grid,TextField,Button,CircularProgress, MenuItem} from "@material-ui/core";
import { connect } from "react-redux";
import RightToLeftSideDrawer from "./RightToLeftSideDrawer";
import { setInvoiceAccountsList } from "../actions/invoiceAccount";
import { setSelectedInvoiceAccount } from "../actions/invoiceAccount";
import AutoCompleteTaxPreset from "./AutoCompleteTaxPreset";
import { addBankAccountApi } from "../services/authService";
import { updateBankApi } from "../services/authService";
import { consoleToLog } from "../util/AppUtil";
import { useSnackbar } from "notistack";
import { logout } from "../actions/invoiceAuth";
import { setSelectedDrawerState } from "../actions/drawer";
import { trackGAEvent } from "../util/AppUtil";
import { useIsMount } from "./useIsMount";

const useStyles = makeStyles((theme) => ({
    labelTextStyles: {
        fontSize: "14px",
        marginBottom: "-8px",
    },
    customMarginTop: {
        marginTop: "16px",
    },
    customColumn1: {
        flexBasis: "46.5%",
        maxWidth: "46.5%",
    },
}));

const AddEditBankDrawer = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const invoice_account_id = props.selectedAccount?.id;
    const isMount = useIsMount();
    
    const [bankName, setBankName] = useState("");
    const [accountHoldername, setAccountHolderName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [errorType, setErrorType] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(-1);

    const accountEnttityList = props.selectedAccount?.entities; 

    useEffect(() => {
        if(!props.fromCreateEditInvoice && Object.keys(props.bankAccountObj).length !== 0) {
            setBankName(props.bankAccountObj?.bank_name);
            setAccountHolderName(props.bankAccountObj?.account_holder_name);
            setAccountNo(props.bankAccountObj?.account_number);
            setSelectedEntity(props.bankAccountObj?.entity?.id);
        } 

    }, [props.bankAccountObj]);

    useEffect(() => {
        if(props.fromDashboardOrAddPayment && props.entityObjForBankAcc) {
            setSelectedEntity(props.entityObjForBankAcc?.id);
            props.setEntityObjForBankAcc(undefined);
            
        }

    }, [props.fromDashboardOrAddPayment]);

    const closeDrawer = () => {
        props.handleBankDrawerClose();
        clearForm();
    }

    const clearForm = () => {
        setBankName("");
        setAccountHolderName("");
        setAccountNo("");
        setErrorType(undefined);
        setErrorMessage("");
        setSelectedEntity(-1);
        Object.keys(props.bankAccountObj).length !== 0 && props.setBankAccountObj({})
    };

    const onAddEditBankClick = () => {
        const invoice_account_id = props.selectedAccount?.id;

        const bank_name=bankName;
        if(bank_name === '') {
            setErrorType(0);
            setErrorMessage('Bank name cannot be empty!');
            return;
        }

        const account_holder_name = accountHoldername;
        if(account_holder_name === '') {
            setErrorType(1);
            setErrorMessage('Account holder name cannot be empty!');
            return;
        }

        const account_number = accountNo;
        if(account_number === '') {
            setErrorType(2);
            setErrorMessage('Account number cannot be empty!');
            return;
        }

        const entity_id = selectedEntity;
        if(entity_id === -1) {
            setErrorType(3);
            setErrorMessage('Please select entity account');
            return;
        }
    

        setLoading(true);
        if(!props.fromCreateEditInvoice && Object.keys(props.bankAccountObj).length !== 0) {
            const id = props.bankAccountObj?.id;
            callUpdateBankApi(invoice_account_id, id, bank_name, account_holder_name, account_number)
        } else {

        addBankAccountApi(invoice_account_id, entity_id, bank_name, account_holder_name, account_number)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response addBankAccountApi: ", res);
                setLoading(false);

                enqueueSnackbar("Bank Account Added SuccessFully", {
                variant: "success",
            });

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === selectedEntity) {
                            let bank_accounts = item.bank_accounts && item.bank_accounts.length > 0 ? item.bank_accounts : [];
                            console.log("item.bank_accounts", item.bank_accounts)
                            console.log("bank_accounts", bank_accounts)
                            item.bank_accounts = [res, ...bank_accounts];
                        }
                        return item
                    })
                };

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                props.fromDashboardOrAddPayment && props.setBankAccObj(res);
                closeDrawer();
                // props.handleDrawerClose();

                trackGAEvent(
                        props.selectedAccount.name,
                        "Bank Account created",
                        `${props.user.firstname} ${props.user.lastname}`
                    );
                })
                .catch((e) => {
                    consoleToLog("Error addBankApi", e);
                    setLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                });
        }
    }

    const callUpdateBankApi = (invoice_account_id, id, bank_name, account_holder_name, account_number) => {
        updateBankApi(invoice_account_id, selectedEntity, id, bank_name, account_holder_name, account_number)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateBankApi: ', res);
                setLoading(false);
                
                enqueueSnackbar('Bank Updated SuccessFully', {variant: 'success'});

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === selectedEntity) {
                            item.bank_accounts = item.bank_accounts.map((bankObj) => {
                                if(bankObj.id === res.id) {
                                    bankObj = res
                                }
                                return bankObj;
                            });
                        }
                        return item
                    })
                };

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                closeDrawer();
                // props.handleDrawerClose();

                trackGAEvent(props.selectedAccount.name, 'Bank Items updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error updateBankApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
        }


    return (
        <RightToLeftSideDrawer title={Object.keys(props.bankAccountObj).length !== 0  ? 'Update Bank' : "Add Bank"} 
            openDrawer={props.openBankDrawer}
            closeDrawer={closeDrawer}>

            <Grid item container style={{ padding: "16px 20px" }}>
                
                <Grid item md={12} lg={12}>
                    <Typography className={classes.labelTextStyles}>
                        Select Entity
                    </Typography>

                    <TextField fullWidth
                        id="account_number"
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={selectedEntity}
                        select
                        onChange={(e) => {
                            setSelectedEntity(e.target.value);
                            setErrorType(undefined);
                            setErrorMessage("");
                        }}
                        error={errorType === 3}
                        helperText={errorType === 3 ? errorMessage : ""}
                        disabled={Object.keys(props.bankAccountObj).length !== 0 || props.fromDashboardOrAddPayment}
                    >
                        <MenuItem value={-1}>Please select entity</MenuItem>
                        {
                            accountEnttityList && accountEnttityList.length > 0 &&
                            accountEnttityList.map((entityItem) => {
                                return <MenuItem key={entityItem.id} value={entityItem.id}>
                                            {entityItem.name}
                                        </MenuItem>
                            })
                        }
                    </TextField>
                </Grid>

                <Grid item md={12} lg={12} className={classes.customMarginTop}>
                    <Typography className={classes.labelTextStyles}>Bank Name</Typography>

                    <TextField fullWidth
                        id="bank_name"
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={bankName}
                        autoComplete="off"
                        placeholder="Enter Bank name"
                        InputLabelProps={{ style: { fontSize: 13, color: "#ccc" } }}
                        onChange={(e) => {
                            setBankName(e.target.value);
                            setErrorType(undefined);
                            setErrorMessage("");
                        }}
                        error={errorType === 0}
                        helperText={errorType === 0 ? errorMessage : ""}
                    />
                </Grid>

                <Grid item md={12} lg={12} className={classes.customMarginTop}>
                    <Typography className={classes.labelTextStyles}>
                        Account Holder Name
                    </Typography>

                    <TextField fullWidth
                        id="account_holder_name"
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={accountHoldername}
                        autoComplete="off"
                        placeholder="Enter Account Holder Name"
                        InputLabelProps={{ style: { fontSize: 13, color: "#ccc" } }}
                        onChange={(e) => {
                            setAccountHolderName(e.target.value);
                            setErrorType(undefined);
                            setErrorMessage("");
                        }}
                        error={errorType === 1}
                        helperText={errorType === 1 ? errorMessage : ""}
                    />
                </Grid>

                <Grid item md={12} lg={12} className={classes.customMarginTop}>
                    <Typography className={classes.labelTextStyles}>
                    Account No
                    </Typography>

                    <TextField fullWidth
                        id="account_number"
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={accountNo}
                        autoComplete="off"
                        placeholder="Enter Account No"
                        InputLabelProps={{ style: { fontSize: 13, color: "#ccc" } }}
                        onChange={(e) => {
                            setAccountNo(e.target.value);
                            setErrorType(undefined);
                            setErrorMessage("");
                        }}
                        error={errorType === 2}
                        helperText={errorType === 2 ? errorMessage : ""}
                    />
                </Grid>

                <div className="modal__footer">
                    <Button variant="contained" color="primary" className="modal__button" onClick={onAddEditBankClick}>
                        {loading && (<CircularProgress size={15} className="loading__style" />)}
                        {Object?.keys(props.bankAccountObj)?.length !== 0 ? 'Update' : 'Save'}    
                    </Button>
                    <Button variant="outlined" style={{minWidth: "130px",}} 
                        onClick={closeDrawer}>
                        
                        Cancel
                    </Button>
                </div>
            </Grid>
        </RightToLeftSideDrawer>
    );
};

const mapStateToProps = (state) => ({
        selectedAccount: state.invoiceAccounts.selectedAccount,
        user: state.invoiceAccounts.user,
        invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBankDrawer);
