import React, {useEffect, useState} from 'react';
import { Stepper, Step, StepLabel, StepConnector, Button, Grid, Typography, Container, Paper} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import SelectClient from './SelectClient';
import AddItems, { validateItems } from './AddItems';
import BusinessInformation from './BusinessInformation';
import {getItemListApi} from '../../../services/authService';
import { consoleToLog } from '../../../util/AppUtil';
import { useSnackbar } from 'notistack';
import {IconButton} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { createProposalApi, getProposalByTaskApi, duplicateProposalApi } from '../../../services/proposalService';
import { Spinner } from '../../Spinner';
import { useIsMount } from '../../useIsMount';
import { history } from '../../../routers/AppRouter';
import ErrorMessageComponent from '../ErrorMessageComponent';

const useStyles = makeStyles((theme) => ({
    stepperContainer: {
        background: 'none',
        padding: '24px 0', // Add padding to ensure proper spacing
    },
    steps: {
        background: 'none',
    },
    stepperRoot: {
        border: '2px solid #BBBBBB',
        zIndex:100,
        height: '40px', // Adjusted height for rectangle
        width: '100%',
        padding:'0px 8px',
        display: 'flex',
        borderRadius: '25px', // Rounded borders
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.light,
        color:'#BBBBBB'
    },
    active: {
        color: '#005EB8',
        border: '2px solid #005EB8',
    },
    completed: {
        color: '#005EB8',
        border: '2px solid #005EB8',
    },
    labelIndexStyle: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'25px',
        height:'25px',
        fontSize:'12px',
        border:'1px solid #BBBBBB',
        borderRadius: '100%',
        marginRight:'8px',
        color:'#BBBBBB'
    },
    active1: {
        border:'none',
        background: '#005EB8',
        color: '#fff'
    },
    completed1: {
        border:'none',
        background: '#005EB8',
        color: '#fff',
    },
    labelText: {
        fontSize:'14px',
        fontWeight: 600
    },
    clearIcon: {
        fontSize:'25px',
        color:'#555',
    },
    iconBtn: {
        marginRight:'16px'
    }
}));

const CustomConnector = withStyles({
    alternativeLabel: {
        top: 20, 
    },
    // active: {
    //     '& $line': {
    //         borderBottom: '2px solid #005EB8',
    //     },
    // },
    // completed: {
    //     '& $line': {
    //         borderBottom: '2px solid #BBBBBB',
    //     },
    // },
    line: {
        border: 0,
        borderBottom: '2px solid #BBBBBB',
    },
})(StepConnector);

const CreateProposal = ({selectedAccount, setProposalObj, setEditProposalArr, duplicate_proposal_obj, onCloseProposalDialog, handleClientDrawerOpen,
    newClientObj, setNewClientObj
}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [activeStep, setActiveStep] = useState(0);

    const [selectedEntity, setSelectedEntity] = useState(-1);
    const [selectedClient, setSelectedClient] = useState({});
    const [showQuantity, setShowQuantity] = useState(true);
    const [quantityUnitTerminology, setQuantityUnitTerminology] = useState('Quantity');
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [businessInfo, setBusinessInfo] = useState('');
    const [items, setItems] = useState([
        { name: '', quantity: '', price: '', periodicity: 'none' }
    ]);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    
    
    const [proposalTaskId, setProposalTaskId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState(0);
    const [showApiError, setShowApiError] = useState(false);
    let interval;

    let stepsWithLabel = ['Client Selection', 'Items Selection', 'Business Information'];
    let steps = duplicate_proposal_obj ? [...stepsWithLabel].slice(0,2) : [...stepsWithLabel];

    useEffect(() => {
        getInvoiceItems();
    }, []);

    useEffect(()=>{
        if(!isMount && proposalTaskId){
            interval = setInterval(getProposalObjByTaskId, 15000);
        }
        return () => clearInterval(interval);
    }, [proposalTaskId])

    useEffect(() => {
        if(!isMount){
            console.log("useEffect1")
            if(proposalTaskId === ""){
                
            }
        }
        return () => clearInterval(interval);
    }, [proposalTaskId]);


    const invoice_account_id = selectedAccount?.id;


    const getInvoiceItems = async() => {
        try {
            const response = await getItemListApi(invoice_account_id);
            const res = response.data;
            consoleToLog('Response getItemListApi: ', res);
            setInvoiceItems(res);
        } catch(e) {
            consoleToLog('Error getItemListApi', e.response);
            if(e.response.data && e.response.data.message) {
                return;
            }
        }
    }

    function stepStyles(props) {
        const { active, completed, labels, index } = props;

        return (
            <div 
                className={clsx(classes.stepperRoot, {
                    [classes.active]: active,
                })}
            >
                <span className={clsx(classes.labelIndexStyle,{
                        [classes.active1]: active,
                    })
                    }
                >{index+1}</span>
                <Typography className={classes.labelText}>
                    {labels}
                </Typography>
            </div>
        );
    }

    const handleStepClick = (index) => {
        activeStep > index && setActiveStep(index);
    };

    const onNextButtonClick = () => {
        switch (activeStep) {
            case 0:
                // Validate SelectClient step
                if (selectedEntity === -1) {
                    setErrorMessage('Please select an entity to continue');
                    setErrorType(1);
                    return;
                } else if (Object.keys(selectedClient).length === 0) {
                    setErrorMessage('Please select a client to continue');
                    setErrorType(2);
                    return;
                }
                break;
            case 1:
                // Validate AddItems step
                if (items.length === 0) {
                    enqueueSnackbar('Please add at least one item to continue', {variant:'error'});
                    return;
                }
                // Validate each item in the items array
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if (!item.name) {
                        setErrorType(3);
                        return;
                    } else if (!item.quantity) {
                        setErrorType(4);
                        return;
                    } else if (!item.price) {
                        setErrorType(5);
                        return;
                    } else if (!item.amount) {
                        setErrorType(6);
                        return;
                    } else if (item.periodicity === '') {
                        setErrorType(7);
                        return;
                    } else {
                        setErrorType(0);
                    }
                }
                break;
            case 2:
                // Validate BusinessInformation step
                if (!businessInfo) {
                    setErrorType(8);
                    setErrorMessage('Please provide business information to continue');
                    return;
                }
                break;
            default:
                break;
        }

        // Clear error message and proceed to the next step
        setErrorMessage('');
        setErrorType(0);
        if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            onCreateProposalClick();
        }
    };

    const resetErrorMessageAndErrorType = () => {
        setErrorMessage('');
        setErrorType(0);
    }

    const onBackButtonClick = () => {
            setActiveStep(activeStep => activeStep - 1);
    }

    const callCreateProposalApi = async() => {
        
        showApiError && setShowApiError(false);

        const entity_id = selectedEntity && selectedEntity.id;
        const entity_name = selectedEntity && selectedEntity.name;
        const client_id = selectedClient && selectedClient.id;
        const quantity_terminology = quantityUnitTerminology;
        const quantity_enable = showQuantity;
        const business_information = businessInfo;
        const currency_id = selectedCurrency?.id;
        const line_items = items.map((obj) => {
            delete obj.amount; delete obj.name;
            return obj
        })

        try {
            const response = await createProposalApi(invoice_account_id, entity_id, client_id, entity_name, currency_id, business_information, line_items, quantity_terminology, quantity_enable);
            const res = response.data;
            consoleToLog('Response createProposalApi: ', res);
            //getProposalObjByTaskId(res.proposal_task_id);
            setLoading(true);
            setProposalTaskId(res.proposal_task_id)
        } catch(e) {
            consoleToLog('Error createProposalApi', e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const getProposalObjByTaskId = async() => {
        try {
            const response = await getProposalByTaskApi(invoice_account_id, proposalTaskId);
            const res = response.data;
            consoleToLog('Response getProposalByTaskApi: ', res);
            if(res.id) {
                setProposalObj(res);
                setEditProposalArr(res.sections);
                showApiError && setShowApiError(false);
                setLoading(false);
                setProposalTaskId('');
                clearInterval(interval);
                history.push(`/proposal/${res.slug}/edit`);
            }
        } catch(e) {
            consoleToLog('Error getProposalByTaskApi', e.response);
            setLoading(false);
            setShowApiError(true);
            clearInterval(interval);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callDuplicateProposalApi = async() => {
        setLoading(true);

        const proposa_id = duplicate_proposal_obj?.id;
        const entity_id = selectedEntity && selectedEntity.id;
        const client_id = selectedClient && selectedClient.id;
        const quantity_enable = showQuantity;
        const currency_id = selectedCurrency?.id;
        const line_items = items.map((obj) => {
            delete obj.amount; delete obj.name;
            return obj
        })

        try {
            const response = await duplicateProposalApi(invoice_account_id, proposa_id, entity_id, client_id, currency_id, quantity_enable, line_items);
            const res = response.data;
            consoleToLog('Response duplicateProposalApi: ', res);
            enqueueSnackbar('Duplicate proposal created successfully', {variant:'success'});
            history.push(`/proposal/${res.slug}/edit`);
            setProposalObj(res);
            setEditProposalArr(res.sections);
            showApiError && setShowApiError(false);
            setLoading(false);
            
        } catch (e) {
            consoleToLog('Error duplicateProposalApi: ', e.response);
            setLoading(false);
            setShowApiError(true);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const onCreateProposalClick = () => {
        if(duplicate_proposal_obj) {
            callDuplicateProposalApi();
        } else {
            callCreateProposalApi();
        }
    }


    return (
        loading
        ?
        <Spinner />
        :
        showApiError
        ?
        <ErrorMessageComponent
            onCloseProposalDialog={onCloseProposalDialog}
            fromCreateProposalComponent={true}
            onCreateProposalClick={onCreateProposalClick}
        />
        :
        <div>

            <Grid item container justifyContent='flex-end'>
                <IconButton onClick={onCloseProposalDialog} 
                    className={classes.iconBtn}>
                    <ClearIcon className={classes.clearIcon}/>
                </IconButton>
            </Grid>

            <Container maxWidth='xl'>

                <div className='container_create_proposal'>
                    <Typography className='create_proposal_heading'>
                        eProcessify Proposal Builder
                    </Typography>

                    <Typography className='create_proposal_descp'>
                        Create customized proposals effortlessly, tailored to your business needs.
                    </Typography>
                    <Stepper 
                        connector={<CustomConnector />} 
                        activeStep={activeStep}
                        className={classes.stepperContainer}
                        alternativeLabel
                    > 
                        {steps.map((label, index) => (
                            <Step key={label} style={{ padding: '0 50px', whiteSpace: 'nowrap', pointerEvents:'none' }}>
                                <StepLabel
                                    style={{ width: 'auto' }}
                                    StepIconComponent={(props) => stepStyles({...props, labels: label, index: index})}
                                />
                            </Step>
                        ))}
                    </Stepper>

                    <Container maxWidth='md'>
                        <Paper variant='outlined' className='padding_24 create_compo_width'>

                            {activeStep === 0 && <SelectClient selectedEntity={selectedEntity}
                                                    selectedClient={selectedClient}
                                                    setSelectedEntity={setSelectedEntity}
                                                    setSelectedClient={setSelectedClient}
                                                    errorMessage={errorMessage}
                                                    errorType={errorType}
                                                    resetErrorMessageAndErrorType={resetErrorMessageAndErrorType}
                                                    handleClientDrawerOpen={handleClientDrawerOpen}
                                                    newClientObj={newClientObj}
                                                    setNewClientObj={setNewClientObj}
                                                />
                            }

                            {activeStep === 1 && <AddItems items={items}
                                                    setItems={setItems}
                                                    invoiceItems={invoiceItems}
                                                    selectedAccount={selectedAccount}
                                                    errorType={errorType}
                                                    setErrorType={setErrorType}
                                                    showQuantity={showQuantity}
                                                    setShowQuantity={setShowQuantity}
                                                    quantityUnitTerminology={quantityUnitTerminology}
                                                    setQuantityUnitTerminology={setQuantityUnitTerminology}
                                                    selectedCurrency={selectedCurrency}
                                                    setSelectedCurrency={setSelectedCurrency}
                                                    setInvoiceItems={setInvoiceItems}
                                                />
                            }

                            {activeStep === 2 && !duplicate_proposal_obj &&
                                                <BusinessInformation businessInfo={businessInfo}
                                                    setBusinessInfo={setBusinessInfo}
                                                    errorType={errorType}
                                                    errorMessage={errorMessage}
                                                    setErrorMessage={setErrorMessage}
                                                    setErrorType={setErrorType}/>}

                            <Grid item container justifyContent='space-between'
                                style={{marginTop:'32px'}}>
                                <Grid item>
                                    {activeStep !== 0 &&
                                    <Button variant='outlined' 
                                        color='primary'
                                        className='btn_width'
                                        onClick={onBackButtonClick}>
                                        Back
                                    </Button>}
                                </Grid>

                                <Grid item>
                                    <Button variant='contained' 
                                        className='btn_width next_btn_style'
                                        onClick={onNextButtonClick}>
                                        {(activeStep === 2 || duplicate_proposal_obj && activeStep === 1) ? 'Create Proposal' : 'Next'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>

                    </Container>
                    
                </div>
            </Container>
    </div>
    );
}

export default CreateProposal;
