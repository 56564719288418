import React, {useState, useRef, useCallback} from 'react';
import {Dialog, Typography, Grid, Tabs, Tab, Paper, Button, TextField, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SignatureCanvas from "react-signature-canvas";
import {DroppedItemTypes} from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import { consoleToLog } from '../../util/AppUtil';
import {uploadUserSignatureApi} from '../../services/documentService';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    tab: {
        flexGrow: 1,
        borderRadius: 0,
        borderBottom: "1px solid #c5b9b9",
    },
    muiTab: {
        fontSize:'14px',
        minWidth:"130px",
        textTransform:'capitalize',
        [theme.breakpoints.down(1400)] : {
            minWidth: '100px'
        }
    },
    footerStyle: {
        flexGrow: 1,
        padding:'8px 16px',
        borderTop:'1px solid #ccc'
    },
    MuiTextFieldroot: {
        background:'#fff',
        '& .MuiOutlinedInput-input': {
            paddingTop: '8px',
            paddingBottom: '8px'
        }
    },
    canvasStyle: {
        display: 'none', // Hide the canvas used to generate the image
    },
    customMarginTop: {
        marginTop:'24px',
    },
    displayFlex: {
        height:'380px', 
        background:'#f7f7f7', 
        display:'flex', 
        flexDirection:'column',
        alignItems:"center",
        position:'relative',
        width:'100%'
    },
    messageStyle: {
        position: 'absolute',
        fontSize:13,
        bottom: 16,
        left:150,
        width:350,
        fontWeight: 500,
        color:'#4a5668'
    },
    messageStyle1: {
        position: 'absolute',
        fontSize:13,
        left:8,
        bottom:8,
        width:'98%',
        fontWeight: 500,
        color:'#4a5668'
    },
    imageContainer: {
        marginTop:'16px',
        display:'flex',
        width:'350px',
        height:'120px',
        justifyContent:'center',
        alignItems:'center',
        background:'#fff'
    }
}));

const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

const SignatureModalComponent = ({ openSignDialog, handleSignDialogClose, droppedItems, setDroppedItems, selectedIndex,
    fromDocumentValue, fromDocumentPreview, callSignByMemberApi, pathname, callSignByClientApi, width, ...props }) => {

    const classes = useStyles();
    const sigPad = useRef(null);
    const canvasRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();
    
    const [value, setValue] = useState(0);
    const [initialValue, setInitialValue] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [apiLoading, setApiLoading] = useState(false);
    const [fileObj, setFileObj] = useState(undefined);
    const [signatureData, setSignatureData] = useState(undefined);

    const {user} = props;

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setInitialValue('');
        setImagePreview(null);
        setFileObj(undefined);
        setSignatureData(undefined);
    };

    const uploadSignatureApi = async() => {
        const signatureFile = dataURLtoFile(
            signatureData, user ? `${user.firstname + ' ' + user.lastname}.png` : 'signature.png'
        );

        setApiLoading(true);

        if(fromDocumentPreview && pathname === 'client') {
                callSignByClientApi(signatureFile);
        } else {

            // Upload signature and get new path
            const response = await uploadUserSignatureApi(signatureFile);
            consoleToLog("Response uploadUserSignatureApi", response);

            if(fromDocumentPreview) {
                if(pathname === 'member') {
                    callSignByMemberApi(response.data.signature_path)
                }
            } else {

                const updatedFormElements = droppedItems;

                updatedFormElements.forEach((item, index) => {
                    if (item.component_type === DroppedItemTypes.SIGNATURE && item.component_label === updatedFormElements[selectedIndex].component_label) {
                        updatedFormElements[index].value_json.signature_image_path = response.data.signature_path;
                        updatedFormElements[index].value_json.signature_image_path_url = response.data.signature_path_url;
                    }
                });
                setDroppedItems([...updatedFormElements]);
                setApiLoading(false);
                handleSignDialogClose();
            }
        }
    }

    const handleSignatureEnd = () => {
        if (sigPad.current) {
            const signatureData = sigPad.current.toDataURL();
            setSignatureData(signatureData);
        }
    };

    const clearSignature = () => {
        if (sigPad.current) {
        if(fromDocumentValue) {
            const updatedFormElements = droppedItems;
            updatedFormElements.forEach((item, index) => {
                if (item.component_type === DroppedItemTypes.SIGNATURE && item.component_label === updatedFormElements[selectedIndex].component_label) {
                    updatedFormElements[index].value_json.signature_image_path = null;
                }
            });
            sigPad.current.clear();
            setDroppedItems([...updatedFormElements]);
        } else {
            sigPad.current.clear();
        }
        }
    };

    const createImageOfSign = () => {
        const imageDataURL = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
        const fileName = `${droppedItems[selectedIndex].component_label}-esign.png`;
        const imageFile = dataURLtoFile(imageDataURL, fileName);
        console.log('image', imageFile);
        
    };

    const generateImage = (val) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = 200; // Set canvas dimensions
        canvas.height = 70;

        // Clear canvas
        context.clearRect(0, 0, canvas.width, canvas.height);
        

        const testElement = document.createElement('div');
        testElement.style.fontFamily = '"Dancing Script", cursive';
        testElement.style.visibility = 'hidden';
        testElement.innerText = 'Test';
        document.body.appendChild(testElement);
        const isFontApplied = window.getComputedStyle(testElement).fontFamily.includes('Dancing Script');
        document.body.removeChild(testElement);

        if (isFontApplied) {
            // Set font and draw text
            context.font = '48px "Dancing Script", cursive';
            context.fillStyle = '#000';
            context.textAlign = 'center';
            context.textBaseline = 'middle';

            context.fillText(val, canvas.width / 2, canvas.height / 2);

            const imageData = canvas.toDataURL('image/png');
            setImagePreview(imageData);
            setSignatureData(imageData);
        } else {
            alert('Font is not applied. Please check your font setup.');
        }
        
    };

    const onSaveClick = () => {
        uploadSignatureApi()
    }

    const handleInitialChange = (e) => {
        const value = e.target.value;
        setInitialValue(value);
        generateImage(value);
    }

    const handleFileChange = async(e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 300 * 1024;

        if(file.size > maxSizeInBytes) {
            enqueueSnackbar('File cannot be greater than 300kb',  {variant:'error'});
        } else {
            consoleToLog("onOrgPhotoSelected",e.target.files[0]);
            setFileObj(e.target.files[0]);
            var oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);
            oFReader.onload = function (oFREvent) {
                setSignatureData(oFREvent.target.result);
                document.getElementById("sign_image").src = oFREvent.target.result;
            };
        }
    }

    return (
        <Dialog
            open={openSignDialog}
            //TransitionComponent={Transition}
            classes={{
                paper: classes.MuiDialogpaper}}
            fullWidth
            fullScreen={width < 700 ? true : false}
            onClose={handleSignDialogClose}>

            <Typography className={classes.dialogTitle}>
            <span>Sign</span>

            <ClearIcon fontSize='xs' onClick={handleSignDialogClose}
                style={{cursor:'pointer', verticalAlign:'inherit'}}/>
            </Typography>

            <Grid item md={12}>
                <Grid item container>
                <Paper
                    className={classes.tab}
                    elevation={1}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Draw Sign" 
                            classes={{
                                root: classes.muiTab
                        }}/>
                        <Tab label="Initials" 
                            classes={{
                                root: classes.muiTab
                        }}/>
                        <Tab label="Upload Sign" 
                            classes={{
                                root: classes.muiTab
                        }}/>
                    </Tabs>
                </Paper>
                <Grid item md={12} className={classes.displayFlex}>
                    {
                        value === 0 && 
                        <div className={classes.customMarginTop}>
                            <div className="signature-container">
                                <Typography>
                                    Draw here
                                </Typography>
                                <SignatureCanvas
                                    id={`sign-${selectedIndex + 1}`}
                                    ref={sigPad}
                                    canvasProps={{
                                    width: width < 700 ? 'inherit' : 350,
                                    height: 150,
                                    className: 'signature-canvas'
                                    }}
                                    onEnd={handleSignatureEnd}
                                />
                            </div>
                            <Button variant='container' 
                                size='small'
                                style={{background:'#222', color:'#fff', marginLeft: width < 700 ? 16 : 0}}
                                onClick={clearSignature}>
                                Clear
                            </Button>
                        </div>
                    }

                    {
                        value === 1 && 
                        <Grid item className={classes.customMarginTop}>
                            <Typography>
                                Enter Initials
                            </Typography>
                            <TextField variant='outlined'
                                placeholder='Enter your initials'
                                fullWidth
                                value={initialValue}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                                onChange={handleInitialChange}
                                style={{width:'300px'}}
                            />
                            {imagePreview && initialValue !== '' && (
                                <Grid item xs={12} className={classes.imageContainer}>
                                    <img src={imagePreview} alt="Signature Preview" style={{ maxWidth: '100%' }} 
                                    crossOrigin='anonymous'
                                    />
                                </Grid>
                            )}
                            <canvas ref={canvasRef} className={classes.canvasStyle}></canvas>
                        </Grid>
                    }

                    {
                        value === 2 && 
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                        <Typography variant='body2' className={classes.customMarginTop} style={{marginBottom:'8px', color:'red'}}>
                            Signature image should not be more than 300kb
                        </Typography>
                        <Grid item >
                            <label>
                                <Button component='div' variant='contained' color='primary'>
                                    Upload
                                </Button>
                                <input style={{display:'none'}}
                                    type="file"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={handleFileChange}
                                />
                            </label>

                        </Grid>
                        {
                            value === 2 && fileObj &&
                            <Grid item style={{ marginTop: '8px' }}>
                                <div className={classes.imageContainer}>
                                    <img src={''}   
                                        id="sign_image" 
                                        style={{ width: '100%' }} 
                                    />
                                </div>
                            </Grid>
                            
                        }
                        </div>
                    }


                    <Grid item container justifyContent='center'>
                        <Grid item md={7}>
                            <Typography className={width < 700 ? classes.messageStyle1 : classes.messageStyle} 
                                style={{marginTop:width< 700 ? '16px' : '0px'}}>
                                By clicking Adopt & Sign, I agree that the chosen signature/initals will be valid electronic
                                representation of my hand-written signature/initials for all purposes when it is used on documents,
                                including legally binding contracts.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                
                <Grid item md={12} className={classes.footerStyle}>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item>
                            <Button
                                onClick={onSaveClick} 
                                variant='contained' color='primary'>
                                {apiLoading && <CircularProgress size={15} style={{color:'#fff', marginRight:'4px'}}/>}
                                Sign
                            </Button>
                        </Grid>
                        
                        <Grid item>
                            <Button onClick={handleSignDialogClose} 
                                variant='outlined' color='primary'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    user: state.invoiceAccounts.user
})

export default connect(mapStateToProps)(SignatureModalComponent);