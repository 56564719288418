import React, {useState} from 'react';
import {Container, Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CoverPagePreview from './CoverPagePreview';
import IntroductionPreview from './IntroductionPreview';
import WhyUsPreview from './WhyUsPreview';
import ReviewsPreview from './ReviewsPreview';
import TeamPreview from './TeamPreview';
import GreetingsPreview from './GreetingsPreview';
import BillingsPreview from './BillingsPreview';
import PaymentTermsPreview from './PaymentTermsPreview';
import { consoleToLog, trackGAEvent } from '../../../util/AppUtil';
import SendIcon from '@material-ui/icons/Send';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {Link} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import {markApporvedRejectedSentApi, downloadProposalPdfApi} from '../../../services/proposalService';
import { useSnackbar } from 'notistack';
import ShowConfirmModal from '../ShowConfirmModal';
import SendEmailComponent from '../../SendEmailComponent';
import { sendProposalMailApi } from '../../../services/proposalService';
import { SelectedDrawerState } from '../../../actions/drawer';
import UpdateProposalTitle from '../edit/UpdateProposalTitle';
import CustomPagePreview from './CustomPagePreview';
import ActivityFeed from '../../ActivityFeed';
import AllActivityFeedDrawer from '../../AllActivityFeedDrawer';
import TopStatus from '../../TopStatus';
import CheckIcon from '@material-ui/icons/Check';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {onCopyClientUrl, AccessLevel} from '../../../util/AppUtil';
import {Popover, MenuItem, Divider} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        height: 'calc(100vh - 70px)', 
        overflowY:'auto',
        overflowX: 'hidden',
        padding: '16px 0px 0px 24px',
        [theme.breakpoints.down('sm')] : {
            padding: '0px'
        }
    },
    container: {
        maxWidth:'1000px',
        marginTop: '40px',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')] : {
            width:'100vw',
            padding:'0px 10px',
            marginTop: '16px',
        }
    },
    listContainer: {
        display:'flex',
        flexDirection:'column',
        gap:'32px'
    },
    normalPreviewContainer: {
        paddingRight:'40px',
        marginTop: '40px',
        marginBottom: '40px',
    },
    moreOptionsStyles: {
        padding:"12px",
        fontSize:"15px"
    }
}))

const componentMap = {
    cover_page: CoverPagePreview,
    introduction: IntroductionPreview,
    why_us: WhyUsPreview,
    reviews: ReviewsPreview,
    team: TeamPreview,
    greetings: GreetingsPreview,
    billing_schedule: BillingsPreview,
    payment_terms: PaymentTermsPreview

};


const PreviewProposal = ({previewProposalPdfArr, proposalObj,  onClosePreview, selectedAccount, user, normalUserPreview,
    showSendEmail, setShowSendEmail, setUpdateProposalObj, drawerState, setProposalObj, onCloseProposalDialog, handleProposalModalClose}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [proposalItem, setProposalItem] = useState(undefined);
    const [apiLoading, setApiLoading] = useState(false);
    const [downloadApiLoading, setDownloadApiLoading] = useState(false);
    const [sendEmailLoading, setSendEmailLoading] = useState(false);
    const [openActivityDrawer, setOpenActivityDrawer] = useState(false);

    const [showMoreOptions, setShowMoreOptions] = useState(null);

    const brand_color = proposalObj?.entity.brand_color;
    const invoice_account_id =  proposalObj?.client?.invoice_account_id;
    const proposal_id = proposalObj.id;
    const entity_id = proposalObj?.entity.id;
    const client_id = proposalObj?.client.id;
    const currency = proposalObj?.currency; 
    const status = proposalObj?.status;
    const entity_name = proposalObj?.entity.name;
    const entity_logo = proposalObj?.entity.logo_url;

    const {access_level} = selectedAccount;
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const handleConfirmDialogOpen = (e, obj) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        let propsosal_obj = {
            id: proposalObj.id,
            ...obj
        }
        setProposalItem(propsosal_obj);
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setProposalItem(undefined);
    }

    const onShowMoreActivities = ()=>{
        setOpenActivityDrawer(true);
    }

     const handleCloseActivityDrawer = ()=>{
        setOpenActivityDrawer(false);
    }

    const proposalPreviewContainer = () => {
        return (
            <Container className={classes.container} 
            style={{ maxWidth: normalUserPreview ? '843px' : '1000px',
                    marginTop: normalUserPreview && '0px',
                    marginBottom: normalUserPreview && '0px',
            }}>
                <TopStatus status={proposalObj?.status}
                    fromInvoicePreview={false}/>
                <Grid item md={12} className={classes.listContainer}>
                    {previewProposalPdfArr.map((item, index) => {
                        const DynamicComponent = componentMap[item.type] || (() => (
                            <CustomPagePreview 
                                item={item} 
                                brand_color={brand_color}
                                index={index}
                                normalUserPreview={normalUserPreview}
                            />
                        ));

                        return  item.show_page &&
                                <div id={index+"_"+item.title} className='preview_list'>
                                    <Grid item md={12}>
                                        {DynamicComponent ? <DynamicComponent item={item} 
                                                                brand_color={brand_color}
                                                                index={index}
                                                                normalUserPreview={normalUserPreview}
                                                                currency={currency}
                                                                entity_name={entity_name}
                                                                entity_logo={entity_logo}
                                                                /> : null}

                                    </Grid>

                                </div>
                            
                            })
                    }
                </Grid>
            </Container>
        )
    }

    const onDownloadProposalClick = async(e) => {
        e.stopPropagation();
        setDownloadApiLoading(true);
        try {
            const response = await downloadProposalPdfApi(invoice_account_id, entity_id, client_id, proposal_id)
            const res = response.data;
            consoleToLog("Response downloadProposalPdfApi: ", res);
            setDownloadApiLoading(false);

            document.getElementById('proposal_download').href = res.result;
            document.getElementById('proposal_download').click();

        }catch(e) {
            consoleToLog("Error downloadProposalPdfApi: ", e.response);
            setDownloadApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callActionApis = async(str) => {

        const data = {};
        setApiLoading(true);

        try {
            const response = await markApporvedRejectedSentApi(invoice_account_id, proposal_id, str, data)
            const res = response.data;
            consoleToLog("Response markApporvedRejectedSentApi: ", res);
            setApiLoading(false);

            enqueueSnackbar(`Proposal updated successfully`, {variant:'success'});

            setProposalObj({...res})
            drawerState === SelectedDrawerState.SHOW_PROPOSALS && setUpdateProposalObj({...res});
            handleConfirmDialogClose();

        }catch(e) {
            consoleToLog("Error markApporvedRejectedSentApi: ", e);
            setApiLoading(false);
            handleConfirmDialogClose();
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const onSendEmailClick = () => {
        setShowSendEmail(true);
    } 

    const callSendProposalMailApi = async(email_array, email_json) => {

        const {subject, body} = email_json;

        setSendEmailLoading(true);
        try {
            const response = await sendProposalMailApi(invoice_account_id, proposal_id, email_array, subject, body)
            const res = response.data;
            consoleToLog("Response sendProposalMailApi: ", res);
            setSendEmailLoading(false);

            enqueueSnackbar(`Proposal sent successfully`, {variant:'success'});

            setProposalObj({...res});
            drawerState === SelectedDrawerState.SHOW_PROPOSALS && setUpdateProposalObj({...res});

        } catch(e) {
            consoleToLog("Error sendProposalMailApi: ", e);
            setSendEmailLoading(false);
            handleConfirmDialogClose();
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const handleMoreOptionsPopoverOpen = (e) => {
        setShowMoreOptions(e.currentTarget);
    }

    const handleMoreOptionsPopoverClose = (e) => {
        setShowMoreOptions(null);
    }

    const showMoreOptionsPopover = () => {
        return (
        <Popover
            id={'more-options-popover'}
            open={Boolean(showMoreOptions)}
            anchorEl={showMoreOptions}
            onClose={handleMoreOptionsPopoverClose}
            style={{marginTop:'40px', width:'250px'}}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{
                paper: classes.paper,
            }}
        >


            {proposalObj?.can_mark_sent &&
            <MenuItem onClick={(e) =>   { 
                    callActionApis('mark_sent');
                    handleMoreOptionsPopoverClose(e);
                }}
                className={classes.moreOptionsStyles}> 
                <CheckIcon fontSize='small'
                    style={{marginRight:'5px', fontSize:'16px'}} />
                    Mark as Sent
            </MenuItem>}

            {proposalObj?.can_mark_sent && <Divider />}

            {proposalObj.can_approve &&
            <MenuItem onClick={(e) => handleConfirmDialogOpen(e, {mark_approved_clicked: true})}
                className={classes.moreOptionsStyles}> 
                <PlaylistAddCheckOutlinedIcon fontSize='small'
                    style={{marginRight:'5px', fontSize:'16px'}} />
                    Mark as Approved
            </MenuItem>}

            {proposalObj.can_approve && <Divider />}

            {proposalObj.can_approve &&
            <MenuItem onClick={(e) => handleConfirmDialogOpen(e, {mark_rejected_clicked: true})}
                className={classes.moreOptionsStyles}> 
                <CancelOutlinedIcon fontSize='small'
                    style={{marginRight:'5px', fontSize:'16px'}} />
                    Mark as Rejected
            </MenuItem>}

            {proposalObj.can_approve && <Divider />}

            <MenuItem className={classes.moreOptionsStyles}
                onClick={(e) => {
                    onCopyClientUrl(proposalObj?.client_url, enqueueSnackbar);
                    handleMoreOptionsPopoverClose();
                }}>
                    <FileCopyOutlinedIcon fontSize='small' style={{marginRight:'5px', color:'#007bfb', fontSize:'16px'}} />
                    Copy URL
            </MenuItem>

        </Popover>)
    }


    const showButtonsUi = () => {
        return (
            <Grid>
                <Grid item container direction='column' 
                    className="proposal_preview"
                >
                    <Button fullWidth 
                        onClick={() => {
                            trackGAEvent(selectedAccount.name, 'Proposal Preview Screen - Send button clicked', `${user.firstname} ${user.lastname}`);
                            onSendEmailClick()
                        }}
                        variant="contained"
                        //disabled={accessLevelViewer}
                        className={`button_purple`}>
                        <SendIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                            Send Email
                    </Button>

                    {proposalObj.can_edit &&
                    <Button fullWidth
                        onClick={onClosePreview}
                        className='preview_action_btns'
                        //disabled={accessLevelViewer}
                        variant="outlined" color='secondary' >
                        <EditOutlinedIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'15px'}} />
                            {((status === 'sent' || status === 'viewed' || status === 'downloaded') && proposalObj.url_key) ? 'Revoke And Edit' : 'Edit'}
                    </Button>} 

                    <Button fullWidth
                        onClick={onDownloadProposalClick}
                        className='preview_action_btns'
                        variant="outlined" color='secondary'>
                        {downloadApiLoading ? <CircularProgress size={15} color='secondary' style={{marginRight:'5px'}}/> 
                            :<PictureAsPdfIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'15px'}} />}
                            Download
                            <Link
                                style={{ display: "none" }}
                                id="proposal_download"
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                download
                            ></Link>
                    </Button>

                    {/* {proposalObj?.can_approve &&
                    <Button fullWidth
                        onClick={(e) => handleConfirmDialogOpen(e, {mark_approved_clicked: true})}
                        className='preview_action_btns'
                        variant="outlined" color='secondary'>
                        <PlaylistAddCheckOutlinedIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                            Mark Approved
                    </Button>}

                    {proposalObj?.can_approve &&
                    <Button fullWidth
                        onClick={(e) => handleConfirmDialogOpen(e, {mark_rejected_clicked: true})}
                        className='preview_action_btns'
                        variant="outlined" color='secondary'>
                        <CancelOutlinedIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                            Mark Rejected
                    </Button> 
                    } */}

                    <Button fullWidth 
                        //onClick={onPrintInvoiceClick}
                        onClick={handleMoreOptionsPopoverOpen}
                        disabled={accessLevelViewer}
                        variant="outlined" color="secondary">
                        <MoreVertIcon fontSize='small'
                            style={{marginRight:'5px', color:'green', fontSize:'18px'}} />
                            More Options
                    </Button>

                {showMoreOptions && showMoreOptionsPopover()}

                </Grid>
                {proposalObj?.activities && 
                        proposalObj?.activities.length>0 
                        && <ActivityFeed activityFeeds={proposalObj?.activities}
                                onShowMoreActivities={onShowMoreActivities}/>
                    }
            </Grid>
        )
    }

    return (
        <div className={classes.previewContainer} style={{height: normalUserPreview ? '100vh' : 'calc(100vh - 70px)'}}>
            {
                normalUserPreview
                ?
                <>
                    <Grid item container alignItems='center' spacing={2}>
                        <Grid item>
                            <div onClick={onCloseProposalDialog} className='edit_prop_icon_btn'>
                                <img src='/images/back_arrow_background.svg' />
                            </div>
                        </Grid>

                        <Grid item>
                            <UpdateProposalTitle proposalObj={proposalObj}
                                invoice_account_id={invoice_account_id}
                                setProposalObj={setProposalObj}
                                fromPreviewProposal={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='flex-end' 
                        className={classes.normalPreviewContainer}>
                        <Grid item md={7}>
                            {proposalPreviewContainer()}
                        </Grid>
                        <Grid item md={showSendEmail ? 5 : 3}>
                            {showSendEmail
                            ?
                            <SendEmailComponent fromProposalDetails={true}
                                sendEmailLoading={sendEmailLoading}
                                showSendEmail={showSendEmail}
                                setShowSendEmail={setShowSendEmail}
                                proposalObj={proposalObj}
                                callSendProposalMailApi={callSendProposalMailApi}
                                apiLoading={apiLoading}
                                handleConfirmDialogOpen={handleConfirmDialogOpen}
                                closeDialog={handleProposalModalClose}
                                callActionApis={callActionApis}
                                />
                            :
                            showButtonsUi()}
                        </Grid>
                    </Grid>
                </>
                :
                proposalPreviewContainer()
            }

            {
                openConfirmDialog &&
                <ShowConfirmModal openConfirmDialog={openConfirmDialog}
                    handleConfirmDialogClose={handleConfirmDialogClose}
                    callActionApis={callActionApis}
                    proposalItem={proposalItem}
                    apiLoading={apiLoading}
                    fromProposalDetails={true}
                />
            }
            {openActivityDrawer && <AllActivityFeedDrawer openActivityDrawer={openActivityDrawer}
                handleCloseActivityDrawer={handleCloseActivityDrawer}
                obj={proposalObj}
                type="proposal"
            />}
        </div>
    );
}

export default PreviewProposal;