import React, {useState} from 'react';
import {Dialog, Typography, Grid, Button, TextField, CircularProgress} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from '@material-ui/core/styles';
import {requestOtpApi, verifyOtpApi} from '../../services/documentService';
import { consoleToLog, generateMd5ForApi } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import {onlyNumberValidate} from '../../util/AppUtil';
import OtpInput from './OtpInput';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    parentContainer: {
        padding:'16px'
    },
    footerStyle: {
        flexGrow: 1,
        padding:'8px 16px',
        borderTop:'1px solid #ccc'
    },
    otpLink: {
        color:"#007bfb",
        fontSize: "12px",
        cursor:'pointer',
        position:'relative',
        top:'-9px',
        '&:hover': {
            textDecoration:'underline',
        }
    },
    infoMessage: {
        fontSize:'13px',
        color:'red',
    }
}))

const VerifyOtpModal = ({ openVerifyDialog, handleVerifyDialogClose, email, documentObj, setSaveOtp, saveOtp, handleSignDialogOpen,
    width
}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [sendOtpLoading, setSendOtpLoading] = useState(false);
    const [sendOtp, setSendOtp] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [count, setCount] = useState(30);

    const callRequestOtpApi = async() => {
        const url = `${process.env.REACT_APP_BASE_URL}/document/${documentObj?.id}/otp/request?email=${email}`
        const md5_hash = generateMd5ForApi(url);
        //console.log('url', url);
        //console.log('md5_hash', md5_hash);
        setSendOtpLoading(true);

        try {
            const response = await requestOtpApi(md5_hash, documentObj?.id, email);
            const res = response.data;
            consoleToLog("Response requestOtpApi: ", res);
            enqueueSnackbar(res.message, {variant:'success'});
            setSendOtpLoading(false);
            setSendOtp(true);

        } catch(e) {
            setSendOtpLoading(false);
            consoleToLog("Error requestOtpApi: ", e.response);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
            }
        }   

    }

    const callVerifyOtpApi = async() => {
        
        const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/otp/verify`);
        setLoading(true);

        try {
            const response = await verifyOtpApi(md5_hash, email, saveOtp);
            const res = response.data;
            consoleToLog("Response verifyOtpApi: ", res);
            enqueueSnackbar(res.message, {variant:'success'});
            setLoading(false);
            setOtpVerified(true);
            handleVerifyDialogClose();
            handleSignDialogOpen();

        } catch(e) {
            setLoading(false);
            consoleToLog("Error verifyOtpApi: ", e.response);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
            }
        }   

    }

    const onSignClick = () => {
        handleVerifyDialogClose();
        handleSignDialogOpen();
    }

    const handleSendOtp = async () => {
        setIsDisabled(true);
        // Call the request OTP API
        await callRequestOtpApi();
        setCount(30);
        const interval = setInterval(() => {
            setCount(prevCount => {
                if (prevCount <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prevCount - 1;
            });
        }, 1000);
        // Re-enable the button after 30 seconds
        setTimeout(() => {
            setIsDisabled(false);
        }, 30000);
    };

    return (
        <Dialog
            open={openVerifyDialog}
            //TransitionComponent={Transition}
            classes={{
                paper: classes.MuiDialogpaper}}
            fullWidth
            onClose={handleVerifyDialogClose}>

            <Typography className={classes.dialogTitle}>
            <span>Verify</span>

            <ClearIcon fontSize='xs' onClick={handleVerifyDialogClose}
                style={{cursor:'pointer', verticalAlign:'inherit'}}/>
            </Typography>
            
            <Grid item md={12} className={classes.parentContainer}>

                    <Grid item container direction='column'
                        justifyContent='center'
                        alignItems='center'
                        spacing={2}>
                        

                        {width < 700 ?
                        <Grid item container alignItems='center' justifyContent='center' spacing={1}>
                            <Grid item md={12}>
                                <TextField variant='outlined'
                                    fullWidth
                                    disabled={true}
                                    value={email}
                                    margin='dense'
                                />
                                <Typography className={classes.infoMessage}>
                                    *In order to sign, you need to verify OTP
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button className={classes.otpLink}
                                    onClick={handleSendOtp}
                                    disabled={isDisabled}>
                                        Send OTP
                                    {sendOtpLoading && <CircularProgress size={15} style={{color:'#222', marginLeft:'8px'}}/>}
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        <Grid item container alignItems='center' justifyContent='center' spacing={1}>
                            <Grid item>
                                <TextField variant='outlined'
                                    fullWidth
                                    disabled={true}
                                    value={email}
                                    margin='dense'
                                    style={{width:'330px'}}
                                />
                                <Typography className={classes.infoMessage}>
                                    *In order to sign, you need to verify OTP
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button className={classes.otpLink}
                                    onClick={handleSendOtp}
                                    disabled={isDisabled}>
                                        Send OTP
                                    {sendOtpLoading && <CircularProgress size={15} style={{color:'#222', marginLeft:'8px'}}/>}
                                </Button>
                            </Grid>
                        </Grid>}


                        {
                            isDisabled && <Typography>
                                You can resend OTP in {count} seconds
                            </Typography>
                        }

                        <Grid item>
                            <Typography variant='body1' style={{textAlign:'center', marginBottom:'-12px'}}>Enter OTP</Typography>
                            <OtpInput setSaveOtp={setSaveOtp}/>
                        </Grid>

                    </Grid>
            </Grid>

            <Grid item md={12} className={classes.footerStyle}>
                <Grid item container alignItems='center' spacing={1}>
                    <Grid item>
                        <Button
                            onClick={callVerifyOtpApi} 
                            variant='contained' color='primary'>
                            {loading && <CircularProgress size={15} style={{color:'#fff', marginRight:'8px'}}/>}
                            Verify
                        </Button>
                    </Grid>
                    
                    <Grid item>
                        <Button onClick={handleVerifyDialogClose} 
                            variant='outlined' color='primary'>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            
        </Dialog>
    );
}

export default VerifyOtpModal;