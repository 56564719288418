import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {addSelectedActivityStatusToInvoiceFilter, removeSelectedActivityStatusFromInvoiceFilter} from '../../actions/invoiceFilters';
import {trackGAEvent} from '../../util/AppUtil';
import {addSelectedActivityStatusToEstimateFilter, removeSelectedActivityStatusFromEstimateFilter} from '../../actions/invoiceFilters';
import {addSelectedActivityStatusToProposalFilter, removeSelectedActivityStatusFromProposalFilter} from '../../actions/proposalFilters';

const ActivityStatusFilter = (props) => {
    const activityStatusNameList = ["Not Send", "Sent", "Viewed", "Downloaded"];
    const activityStatusValueList = ["none", "sent", "viewed", "downloaded"];

    const activityStatusClassNames = ["draft__status", "sent__status", "viewed__status", "paid__status"];
    const activityStatusClassNames1 = ["draft__background", "sent__background", "viewed__background", "paid__background"];

    let statusNameList = [...activityStatusNameList];
    let statusValueList = [...activityStatusValueList];
    let statusClassNames = [...activityStatusClassNames];
    let statusClassNames1 = [...activityStatusClassNames1];

    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        if(props.fromProposalListComponent) {
            return props.selectedActivityStatusProposalFilter?.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else if(props.fromEstimatesComponent) {
            return props.selectedActivityStatusEstimateFilter?.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else {
            return props.selectedActivityStatusInvoiceFilter?.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        }
    }

    const onStatusClick = (selectedValue) => {
        let selectedStatus;
        if(props.fromProposalListComponent) {
            selectedStatus = props.selectedActivityStatusProposalFilter && props.selectedActivityStatusProposalFilter.length > 0 ? props.selectedActivityStatusProposalFilter?.filter((status) => status === selectedValue) : [];
            selectedStatus && selectedStatus.length > 0 ? props.removeSelectedActivityStatusFromProposalFilter(selectedValue) : props.addSelectedActivityStatusToProposalFilter(selectedValue);
            trackGAEvent(props.selectedAccount.name, 'Proposal filter - activity status filter changed');
        } else if(props.fromEstimatesComponent) {
            selectedStatus = props.selectedActivityStatusEstimateFilter && props.selectedActivityStatusEstimateFilter.length > 0 ? props.selectedActivityStatusEstimateFilter?.filter((status) => status === selectedValue) : [];
            selectedStatus && selectedStatus.length > 0 ? props.removeSelectedActivityStatusFromEstimateFilter(selectedValue) : props.addSelectedActivityStatusToEstimateFilter(selectedValue);
            trackGAEvent(props.selectedAccount.name, 'Estimate filter - activity status filter changed');
        } else {
            selectedStatus = props.selectedActivityStatusInvoiceFilter && props.selectedActivityStatusInvoiceFilter.length > 0 ? props.selectedActivityStatusInvoiceFilter?.filter((status) => status === selectedValue) : [];
            selectedStatus && selectedStatus.length > 0 ? props.removeSelectedActivityStatusFromInvoiceFilter(selectedValue) : props.addSelectedActivityStatusToInvoiceFilter(selectedValue);
            trackGAEvent(props.selectedAccount.name, 'Invoices filter - activity status filter changed');
        }   

    }

    return (
        <div style={{marginTop:'18px'}}>
            <Typography style={{fontSize:'14px'}}>
                Activity            
            </Typography> 
            <Grid container lg={12} style={{marginTop:'4px'}} >
                {statusNameList.map((status, index) => {
                        return <Grid item
                                    className={statusClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={status}
                                    onClick={() => {
                                        onStatusClick(statusValueList[index])
                                        props.pageReset();
                                    }}
                                >
                            <span className={`circle ${statusClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    selectedActivityStatusInvoiceFilter: state.invoiceFilters.selectedActivityStatusInvoiceFilter,
    selectedActivityStatusEstimateFilter: state.invoiceFilters.selectedActivityStatusEstimateFilter,

    selectedActivityStatusProposalFilter: state.proposalFilters.selectedActivityStatusProposalFilter,

});

const mapDispatchToProps = (dispatch) => ({
    addSelectedActivityStatusToInvoiceFilter: (item) => dispatch(addSelectedActivityStatusToInvoiceFilter(item)),
    removeSelectedActivityStatusFromInvoiceFilter: (item) => dispatch(removeSelectedActivityStatusFromInvoiceFilter(item)),

    addSelectedActivityStatusToEstimateFilter: (item) => dispatch(addSelectedActivityStatusToEstimateFilter(item)),
    removeSelectedActivityStatusFromEstimateFilter: (item) => dispatch(removeSelectedActivityStatusFromEstimateFilter(item)),
    
    addSelectedActivityStatusToProposalFilter: (item) => dispatch(addSelectedActivityStatusToProposalFilter(item)), 
    removeSelectedActivityStatusFromProposalFilter: (item) => dispatch(removeSelectedActivityStatusFromProposalFilter(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityStatusFilter);