import React, {useState, useEffect} from 'react';
import { Typography, ListItem, Button, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'; 
import {connect} from 'react-redux';  
import { useIsMount } from './useIsMount';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { trackGAEvent, shouldDisable, AccessLevel } from '../util/AppUtil';
import {AppRoutes} from '../util/AppUtil';
import { history } from '../routers/AppRouter';
import { useLocation , useParams} from 'react-router-dom';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import { clearSelectedTransactionFilters } from '../actions/transactionFilters';
import { setHeightForComponent } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    leftmenu : {
        flexGrow: 1,
        backgroundColor: "white", 
        transition: ".5s", 
        overflow: "auto", 
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    text : {
        color:'#202020',
        fontWeight: 600,
    },
    icons : {
        marginRight:'8px',
        marginLeft: '2px', 
        fontSize: '16px',
        lineHeight: 0
    },
    listStyle: {
       marginLeft:'auto !important',
       color: '#ccc',
       padding:'0 4px 0px 8px',
    },
    labels : {
        display:'flex',
        alignItems:'center',
        padding:'10px 18px',
        marginTop: '5px',
        marginBottom: '10px'
    },
    labels_left : {
        display:'flex',
        alignItems:'center',
        padding:'10px 18px 10px 35px',
        marginTop: '5px',
        marginBottom: '8px'
    },
    rightdownicon : {
        margin: '0px 0px 0px 4px',
        fontSize: '25px',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        }
    },
    MuiAccordionSummaryroot : {
        '&.Mui-expanded' : {
            marginBottom: '-3px'
        }
    },
    MuiAccordionSummaryexpandIcon : {
        transform: 'rotate(-90deg)',
        '&.Mui-expanded' : {
        transform: 'rotate(0deg)'
        }
    },
    detail : {
        padding: '0px 5px 0px 28px',
        display: "block"
    },
    text : {
        color:'#202020',
        fontWeight: 600,
    },
    listitem : {
        fontSize: '15px',
        fontWeight: 400,
        color:'#202020',
        '&:first-child' : {
            marginTop:'-8px'
        },
        '&:selected' : {
            background:'transparent'
        }    
    },
    onHover: {
        '& .settingsIcon': {
            visibility:'hidden'
        },
        '&:hover' : {
            '& .settingsIcon' : {
                visibility:'visible'
            }
        }
    },
    estimateSettingsIcon: {
        fontSize:'19px',
        color:'#555',
        lineHeight: 0
    },
    materialIconsSettings: {
        fontSize:'19px',
        color:'#555',
        marginRight:'5px'
    },
    menuSectionHeader:{ 
        marginTop:'20px', 
        paddingLeft: "16px", 
        fontSize: '12px', 
        color: '#366171' 
    }
}));

const LeftMenuComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const location = useLocation();
    const pathname = location?.pathname;

    useEffect(() => {
        switch (pathname) {
            case AppRoutes.DASHBOARD:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
                break;
            case AppRoutes.INVOICES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_INVOICES);
                break;
            case AppRoutes.TRANSACTIONS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_TRANSACTIONS);
                break;
            case AppRoutes.CLIENTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_CLIENTS);
                break;
            case AppRoutes.ITEMS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ITEMS);
                break;
            case AppRoutes.MEMBERS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_MEMBERS);
                break;
            case AppRoutes.SETTINGS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
                break;
            case AppRoutes.PROFILE:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_PROFILE);
                break;
            case AppRoutes.ONBOARDING:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
                break;
            case AppRoutes.CLIENT_REPORTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_CLIENT_REPORTS);
                break;
            case AppRoutes.ITEM_REPORTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ITEM_REPORTS);
                break;
            case AppRoutes.ESTIMATES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ESTIMATES);
                break;
            case AppRoutes.PROPOSALS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_PROPOSALS);
                break;
            case AppRoutes.VENDORS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_VENDORS);
                break;
            case AppRoutes.BILL_CATEGORIES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_BILL_CATEGORIES);
                break;
            case AppRoutes.DOCUMENTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_DOCUMENTS);
                break;
            case AppRoutes.BILLS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_BILLS);
                break;
            case AppRoutes.PAYMENTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_PAYMENTS);
                break;
            // Add other cases as needed
            default:
                break;
        }
}, [pathname])

    const proformaEnabled = props.selectedAccount.general_settings?.proforma_enabled;
    const enableTDS = props.selectedAccount?.enable_tds;
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const accessLevelOwner = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.OWNER) ? true : false;

    const clearAllStoredState = () => {
        props.invoicesComponentState && props.setInvoiceComponentState();
        props.transactionsComponentState && props.setTransactionComponentState();
        props.estimateComponentState && props.setEstimateComponentState();
        props.proposalsComponentState && props.setProposalsComponentState();
        props.documentsComponentState && props.setDocumentsComponentState();
        props.billComponentState && props.setBillComponentState();
    }   

    const onDashboardTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Dashboard tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.DASHBOARD);
    }

    const onInvoicesTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.INVOICES);
        props.clearSelectedInvoiceFilters();
    }

    const onBillTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Bills tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.BILLS);
        props.clearSelectedInvoiceFilters();
    }

    const onPaymentsClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Payments tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.PAYMENTS);
    }

    const onEstimatesTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Estimates tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.ESTIMATES);
        props.clearSelectedInvoiceFilters();
    }

    const onTransactionsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Transactions tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.clearSelectedTransactionFilters();
        clearAllStoredState();
        history.push(AppRoutes.TRANSACTIONS);
    }

    const onSettingsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Settings tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.SETTINGS);
    }

    const onClientsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Clients tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.CLIENTS);
    }

    const onVendorsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Vendors tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.VENDORS);
    }

    const onItemsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Items tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.ITEMS);
    }
    const onBillCategoriesTabClick=()=>{
        trackGAEvent(props.selectedAccount.name, 'Bill categories tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.BILL_CATEGORIES);
    }

    const onAccountMemberClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Members tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.MEMBERS);
    }

    const onOnboardingTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Onboarding tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.ONBOARDING);
    }

    const onRevenueByClientClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Client Reports tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.CLIENT_REPORTS);
    }

    const onRevenueByItemsClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Item Reports tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.ITEM_REPORTS);
    }

    const onEstimatesSettingsIconClick = (e) => {
        e.stopPropagation();
        props.handleEstimatesSettingsModalOpen();
    }

    const onProposalsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Proposals tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.PROPOSALS);
    }

    const onEsignClick = () => {
        trackGAEvent(props.selectedAccount.name, 'E-Sign tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        history.push(AppRoutes.DOCUMENTS);
    }

    return (
        <div className={classes.leftmenu} 
                style={{height:setHeightForComponent(props.isInvoiceAccountPlanFree, props.entityLimitExceeded)}}
                >
                {accessLevelOwner && props.selectedAccount.onboarding &&
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ONBOARDING}   
                    onClick={onOnboardingTabClick}
                >
                    {/* {<i className={`flaticon-settings ${classes.icons}`}></i> } */}
                    <SettingsOutlinedIcon className={classes.materialIconsSettings} />
                    <Typography variant="body1" className={classes.text}>Onboarding</Typography>
                </ListItem>}
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_DASHBOARD}   
                    onClick={onDashboardTabClick}
                >
                    {/* {<i className={`flaticon-folder ${classes.icons}`}></i> } */}
                    <FolderOutlinedIcon className={classes.materialIconsSettings} />
                    <Typography variant="body1" className={classes.text}>Dashboard</Typography>
                </ListItem>
                <Typography variant="body1" 
                    className={classes.menuSectionHeader}>
                    Receivables
                </Typography>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_INVOICES}   
                    onClick={onInvoicesTabClick}
                >
                    {<LocalAtmOutlinedIcon className={classes.materialIconsSettings} /> }
                    <Typography variant="body1" className={classes.text} >Invoices</Typography>
                </ListItem>
                <ListItem button className={`${classes.labels_left} ${classes.onHover}`}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ESTIMATES}   
                    onClick={onEstimatesTabClick}
                >
                    <ReceiptOutlinedIcon className={classes.materialIconsSettings}/>
                    <Typography variant="body1" className={classes.text}>{props.selectedAccount.estimate_terminology}</Typography>
                    {accessLevelOwner &&
                    <Button color='primary' className={'settingsIcon'} style={{marginLeft:'auto', minWidth:'20px', padding:0}}
                        onClick={onEstimatesSettingsIconClick}>
                        {/* <i className={`flaticon-settings ${classes.estimateSettingsIcon}`}></i> */}
                        <SettingsOutlinedIcon className={classes.estimateSettingsIcon} />
                    </Button>}
                        
                </ListItem>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_TRANSACTIONS}   
                    onClick={onTransactionsTabClick}
                >
                    {/* { <i className={`flaticon-clipboards ${classes.icons}`}></i> } */}
                    <AssignmentOutlinedIcon className={classes.materialIconsSettings}/>
                    <Typography variant="body1" className={classes.text}>Receipts</Typography>  
                </ListItem>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_CLIENTS}   
                    onClick={onClientsTabClick}
                >
                    {/* {<i className={`flaticon-business-and-trade ${classes.icons}`}></i>} */}
                    <BusinessOutlinedIcon className={classes.materialIconsSettings}/>
                    <Typography variant="body1" className={classes.text}>Clients</Typography>  
                </ListItem>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ITEMS}   
                    onClick={onItemsTabClick}
                >
                    <ListOutlinedIcon className={classes.materialIconsSettings}/>
                    <Typography variant="body1" className={classes.text}>Items</Typography>  
                </ListItem>

                {/* <Typography variant="body1" 
                    className={classes.menuSectionHeader}>
                    Payables
                </Typography>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_BILLS}   
                    onClick={onBillTabClick}
                >
                    {<LocalAtmOutlinedIcon className={classes.materialIconsSettings} /> }
                    <Typography variant="body1" className={classes.text} >Bills</Typography>
                </ListItem>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_PAYMENTS}   
                    onClick={onPaymentsClick}
                >
                    {<AssignmentOutlinedIcon className={classes.materialIconsSettings} /> }
                    <Typography variant="body1" className={classes.text} >Payments</Typography>
                </ListItem>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_VENDORS}   
                    onClick={onVendorsTabClick}
                >
                    {<BusinessOutlinedIcon className={classes.materialIconsSettings} /> }
                    <Typography variant="body1" className={classes.text} >Vendors</Typography>
                </ListItem>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_BILL_CATEGORIES}   
                    onClick={onBillCategoriesTabClick}
                >
                    {<ListOutlinedIcon className={classes.materialIconsSettings} /> }
                    <Typography variant="body1" className={classes.text}>Bill Categories</Typography>
                </ListItem> */}
                <Typography variant="body1" 
                    className={classes.menuSectionHeader}>
                    Reports
                </Typography>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_CLIENT_REPORTS}   
                    onClick={onRevenueByClientClick}
                >
                    {<LocalAtmOutlinedIcon className={classes.materialIconsSettings} /> }
                    <Typography variant="body1" className={classes.text}>Revenue by Client</Typography>
                </ListItem>
                <ListItem button className={classes.labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ITEM_REPORTS}   
                    onClick={onRevenueByItemsClick}
                >
                    {<LocalAtmOutlinedIcon className={classes.materialIconsSettings} /> }
                    <Typography variant="body1" className={classes.text}>Revenue by Items</Typography>
                </ListItem>
                
                {/* <Accordion elevation={0} defaultExpanded="false" 
                    classes={{
                        root: classes.MuiAccordionroot
                    }}
                >
                    <div>
                        <AccordionSummary
                            style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                            //className={classes.summary}
                            expandIcon={<ExpandMoreIcon className={`${classes.rightdownicon}`}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            classes = {{
                                root: classes.MuiAccordionSummaryroot,
                                expandIcon: classes.MuiAccordionSummaryexpandIcon
                            }}
                        >
                            <Typography className={classes.text} variant="body1">Reports</Typography>
                        </AccordionSummary>
                    </div>
                    <AccordionDetails  className={classes.detail}>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.listitem}
                                selected={props.drawerState === SelectedDrawerState.SHOW_CLIENT_REPORTS}   
                                onClick={onRevenueByClientClick}
                                >
                                    Revenue by Client
                            </ListItem>
                            <ListItem button className={classes.listitem}
                                selected={props.drawerState === SelectedDrawerState.SHOW_ITEM_REPORTS}   
                                onClick={onRevenueByItemsClick}
                                >
                                    Revenue by Items
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion> */}
                
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_PROPOSALS}   
                    onClick={onProposalsTabClick}
                >
                    {/* {<i className={`flaticon-business-and-trade ${classes.icons}`}></i>} */}
                    <BusinessCenterOutlinedIcon className={classes.materialIconsSettings} style={{position:'relative', top:'-1px'}}/>
                    <Typography variant="body1" className={classes.text}>Proposals</Typography>  
                </ListItem>
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_DOCUMENTS}   
                    onClick={onEsignClick}
                >
                    {/* {<i className={`flaticon-business-and-trade ${classes.icons}`}></i>} */}
                    <img src={'/images/digital_signature.png'} style={{width:'18px', height:'18px', position:'relative', top:'-2px'}}/>
                    <Typography variant="body1" className={classes.text}
                    style={{marginLeft:'8px'}}
                    >E-Sign</Typography>  
                </ListItem>
                {!accessLevelViewer &&
                <ListItem button className={classes.labels}
                        selected={props.drawerState === SelectedDrawerState.SHOW_MEMBERS}   
                        onClick={onAccountMemberClick}>
                            {/* <i className={`flaticon-group ${classes.icons}`}></i> */}
                            <PeopleOutlineIcon className={classes.materialIconsSettings}/>
                            <Typography variant="body1" className={classes.text}>Members</Typography>
                </ListItem>}
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_SETTINGS}   
                    onClick={onSettingsTabClick}
                >
                    {/* {<i className={`flaticon-settings ${classes.icons}`}></i>} */}
                    <SettingsOutlinedIcon className={classes.materialIconsSettings}/>
                    <Typography variant="body1" className={classes.text}>Settings</Typography>  
                </ListItem>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    showEstimateScreen: state.invoiceAccounts.showEstimateScreen
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuComponent);